<script>
import axios from "axios";
import { Btn, Modal, Collapse, ProgressBar } from "uiv";
import { mapGetters } from "vuex";

export default {
  components: { Btn, Modal, ProgressBar, Collapse },
  props: {
    sm: { type: String, required: true },
    conector: { type: String, required: true },
    lineasAereas: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      show: false,
      master: null,
      preloader: true,
      porcentaje: 60,
      showBancos: true,
      showOtrasFormas: true,
      showTarjetasBancos: true
    };
  },
  computed: {
    ...mapGetters(["getURL"]),

    isBancos() {
      return _.has(this.master, "bancos") && this.master.bancos;
    },
    getBancos() {
      return this.isBancos ? this.master.bancos : false;
    },

    isTarjetasBancos() {
      return (
        _.has(this.master, "tarjetas_bancos") && this.master.tarjetas_bancos
      );
    },
    getTarjetasBancos() {
      return this.isTarjetasBancos ? this.master.tarjetas_bancos : false;
    },

    isOtrasFormas() {
      return _.has(this.master, "otras_formas") && this.master.otras_formas;
    },
    getOtrasFormas() {
      return this.isOtrasFormas ? this.master.otras_formas : false;
    }
  },
  methods: {
    open() {
      this.show = true;
      this.cargar();
    },
    cargar() {
      if (this.master === null) {
        let url = this.getURL(
          `/formas_pago/popup/json?modulo=${this.sm}&conector=${this.conector}`
        );
        axios.get(url).then(response => {
          this.preloader = false;
          if (response.data) {
            this.master = response.data;
          }
        });
      }
    }
  }
};
</script>

<template>
  <div>
    <btn block size="sm" type="default" @click="open">
      <slot>
        <i class="fa fa-credit-card"></i>
        Bancos & Tarjetas
      </slot>
    </btn>

    <modal v-model="show" size="lg" :header="false" :footer="false" append-to-body>
      <div v-if="preloader" class="jumbotron">
        <h3>Cargando contenido</h3>
        <progress-bar v-model="porcentaje" striped active></progress-bar>
      </div>
      <div v-else class="formas">
        <div class="page-header">
          <h2>
            <i class="fa fa-credit-card-alt"></i>
            Promociones
          </h2>
        </div>

        <div v-if="isBancos" class="formas_grupo">
          <div class="titulo">
            Promociones Bancarias
            <btn type="link" size="sm" @click="showBancos=!showBancos">
              <i class="fa" :class="(showBancos) ? 'fa-chevron-up' : 'fa-chevron-down' "></i>
            </btn>
          </div>
          <collapse v-model="showBancos" id="formas_bancos">
            <div class="panel panel-default forma" v-for="(banco, key) in getBancos" :key="key">
              <div class="panel-body">
                <div class="row row-20">
                  <div class="col-xs-3">
                    <div class="img-bg" :style="{backgroundImage: 'url(' + banco.img+ ')'}"></div>
                  </div>

                  <div class="col-xs-9">
                    <h4 class="forma-h4" v-html="banco.nombre"></h4>
                    <a class="forma-a" data-toggle="collapse" :href="'#banco-' + banco.id">
                      <i class="fa fa-chevron-up"></i>
                      Ver Promociones
                    </a>
                  </div>
                </div>
              </div>
              <div class="collapse" :id="'banco-' + banco.id">
                <div class="list-group tarjetas">
                  <div
                    class="list-group-item"
                    v-for="(tarjeta, keyT) in banco.tarjetas"
                    :key="keyT"
                  >
                    <div class="row row-20">
                      <div class="col-xs-3">
                        <img class="img-responsive img-tarjeta" :src="tarjeta.img" />
                      </div>

                      <div class="col-xs-9">
                        <h4 class="list-group-item-heading" v-html="tarjeta.nombre"></h4>
                        <div class="couta" v-for="(forma, keyF) in tarjeta.formas" :key="keyF">
                          <ul class="list-inline" v-if="forma.productos">
                            <li>
                              <b>Productos:</b>
                            </li>
                            <li v-for="(producto, keyP) in forma.productos" :key="keyP">
                              <i :class="producto.icon"></i>
                              <span v-html="producto.nombre"></span>
                            </li>
                          </ul>

                          <ul class="list-inline" v-if="forma.lineas_aereas && lineasAereas">
                            <li>
                              <b>Lineas Aereas:</b>
                            </li>
                            <li
                              v-for="(linea, keyL) in forma.lineas_aereas"
                              :key="keyL"
                              v-html="linea"
                            ></li>
                          </ul>

                          <table class="table table-condensed">
                            <tbody>
                              <tr class="active">
                                <th>Cuota</th>
                                <th>C.F.T</th>
                                <th>T.E.A</th>
                                <th v-html="'Inter&eacute;s'"></th>
                                <th>Vigencia</th>
                                <th>Comentario</th>
                              </tr>

                              <tr v-for="(cuota, keyC) in forma.cuotas" :key="keyC">
                                <th class="text-monospace" v-html="cuota.cuota"></th>
                                <td class="text-monospace" v-html="cuota.cft"></td>
                                <td class="text-monospace" v-html="cuota.tea"></td>
                                <td class="text-monospace" v-html="cuota.interes"></td>
                                <td
                                  class="text-monospace small"
                                  v-if="cuota.vigencia"
                                  v-html="cuota.vigencia"
                                ></td>
                                <td class="text-monospace small active" v-else></td>

                                <td class="small" v-html="cuota.comentario"></td>
                              </tr>
                            </tbody>
                          </table>

                          <div
                            class="forma-comentario"
                            v-if="forma.comentario"
                            v-html="forma.comentario"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </collapse>
        </div>

        <div v-if="isTarjetasBancos" class="formas_grupo">
          <div class="titulo">
            <span v-html="'Promociones con tarjetas de cr&eacute;dito'"></span>
            <btn type="link" size="sm" @click="showTarjetasBancos=!showTarjetasBancos">
              <i class="fa" :class="(showTarjetasBancos) ? 'fa-chevron-up' : 'fa-chevron-down' "></i>
            </btn>
          </div>
          <collapse v-model="showTarjetasBancos" id="formas_tarjetas">
            <div
              class="panel panel-default forma"
              v-for="(tarjeta, key) in getTarjetasBancos"
              :key="key"
            >
              <div class="panel-body">
                <div class="row row-20">
                  <div class="col-xs-3">
                    <div class="img-bg" :style="{backgroundImage: 'url(' + tarjeta.img + ')'}"></div>
                  </div>

                  <div class="col-xs-9">
                    <h4 class="forma-h4" v-html="tarjeta.nombre"></h4>

                    <a class="forma-a" data-toggle="collapse" :href="'#tarjeta-' + tarjeta.id">
                      <i class="fa fa-chevron-up"></i>
                      Ver Promociones
                    </a>
                  </div>
                </div>
              </div>

              <div class="collapse" id="tarjeta-{$tarjeta.id}">
                <div class="list-group" v-for="(forma, keyF) in tarjeta.formas" :key="keyF">
                  <div class="list-group-item">
                    <div class="row row-20">
                      <div class="col-xs-9 col-xs-offset-3">
                        <div class="couta">
                          <ul class="list-inline" v-if="forma.productos">
                            <li>
                              <b>Productos:</b>
                            </li>
                            <li v-for="(producto, keyP) in forma.productos" :key="keyP">
                              <i :class="producto.icon"></i>
                              <span v-html="producto.nombre"></span>
                            </li>
                          </ul>

                          <ul class="list-inline" v-if="forma.lineas_aereas && lineasAereas">
                            <li>
                              <b>Lineas Aereas:</b>
                            </li>
                            <li
                              v-for="(linea, keyL) in forma.lineas_aereas"
                              :key="keyL"
                              v-html="$linea"
                            ></li>
                          </ul>

                          <table class="table table-condensed">
                            <tbody>
                              <tr class="active">
                                <th>Cuota</th>
                                <th>C.F.T</th>
                                <th>T.E.A</th>
                                <th v-html="'Inter&eacute;s'"></th>
                                <th>Vigencia</th>
                                <th>Comentario</th>
                              </tr>
                              <tr v-for="(cuota, keyC) in forma.cuotas" :key="keyC">
                                <th class="text-monospace" v-html="cuota.cuota"></th>
                                <td class="text-monospace" v-html="cuota.cft"></td>
                                <td class="text-monospace" v-html="cuota.tea"></td>
                                <td class="text-monospace" v-html="cuota.interes"></td>
                                <td class="text-monospace small" v-html="cuota.vigencia"></td>
                                <td class="small" v-html="cuota.comentario"></td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="forma-comentario" v-if="forma.texto" v-html="forma.texto"></div>
                          <div
                            class="forma-comentario"
                            v-if="forma.comentario"
                            v-html="forma.comentario"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </collapse>
        </div>

        <div v-if="isOtrasFormas" class="formas_grupo">
          <div class="titulo">
            Otros medios de pago
            <btn type="link" size="sm" @click="showOtrasFormas=!showOtrasFormas">
              <i class="fa" :class="(showOtrasFormas) ? 'fa-chevron-up' : 'fa-chevron-down' "></i>
            </btn>
          </div>
          <collapse v-model="showOtrasFormas" id="formas_otras">
            <div
              class="panel panel-default forma"
              v-for="(forma, key) in getOtrasFormas"
              :key="key"
            >
              <div class="panel-body">
                <div class="row row-20">
                  <div class="col-xs-3">
                    <div class="img-bg" :style="{backgroundImage: 'url(' + forma.img+ ')'}"></div>
                  </div>

                  <div class="col-xs-9">
                    <h4 class="forma-h4" v-html="forma.nombre"></h4>

                    <a class="forma-a" data-toggle="collapse" :href="'#forma-' + forma.id">
                      <i class="fa fa-chevron-up"></i>
                      Ver Promociones
                    </a>
                  </div>
                </div>
              </div>

              <div class="collapse" :id="'forma-' + forma.id">
                <div class="list-group">
                  <div class="list-group-item">
                    <div class="row row-20">
                      <div class="col-xs-3"></div>
                      <div class="col-xs-9">
                        <h4 class="list-group-item-heading">
                          Cargo Administrativo:
                          <span class="text-monospace">
                            <span v-html="forma.cargo"></span>%
                          </span>
                        </h4>

                        <ul class="list-inline" v-if="forma.productos">
                          <li>
                            <b>Productos:</b>
                          </li>
                          <li v-for="(producto, keyP) in forma.productos" :key="keyP">
                            <i :class="producto.icon"></i>
                            <span v-html="producto.nombre"></span>
                          </li>
                        </ul>

                        <ul class="list-inline" v-if="forma.lineas_aereas && lineasAereas">
                          <li>
                            <b>Lineas Aereas:</b>
                          </li>
                          <li
                            v-for="(linea, keyL) in forma.lineas_aereas"
                            :key="keyL"
                            v-html="linea"
                          ></li>
                        </ul>

                        <div
                          class="list-group-item-text list-group-item-informacion"
                          v-if="forma.texto"
                          v-html="forma.texto"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </collapse>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>
.jumbotron {
  text-align: center;
  color: #383d41;
  background-color: #e2e3e5;
  margin-bottom: 0;
}
.jumbotron > h3 {
  margin: 0 0 25px;
}

.formas {
}

.formas .text-monospace {
  font-family: monospace;
}

.formas .page-header {
  text-align: center;
  border-bottom: 0;
  margin: 0;
  padding: 10px 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 5;
  -webkit-border-radius: 6px;
  -webkit-border-bottom-right-radius: 1px;
  -webkit-border-bottom-left-radius: 1px;
  -moz-border-radius: 6px;
  -moz-border-radius-bottomright: 1px;
  -moz-border-radius-bottomleft: 1px;
  border-radius: 6px;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
}
.formas .page-header h2 {
  font-size: 25px;
  font-weight: 400;
  margin: 0;
}
.formas .page-header h2 small {
  font-size: 20px;
  color: #f2f2f2;
  font-style: normal;
  font-weight: 100;
}

.formas .titulo {
  text-align: center;
  padding: 10px 15px 10px;
  background: #ffffff;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
}

/* Forma */
.formas .forma {
  border: 0;
  margin-bottom: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}
.formas .forma:last-child {
  border-bottom: 0;
}

.formas .forma .collapse {
  position: relative;
  z-index: 2;
}

.formas .forma .list-group {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.formas .forma .list-group .list-group-item.loading {
  background: #e6e9ed;
  border: 0;
}

.formas .forma .list-group .list-group-item.loading .progress {
  background: #adabab;
  margin-bottom: 0;
}
.formas .forma .list-group .list-group-item.loading .progress .progress-bar {
  width: 40%;
}

.formas .forma .list-group .list-group-item.loading .list-group-item-text {
  text-align: center;
  margin: 0 0 10px;
  color: #555;
  font-size: 13px;
}

/* Caja Principal */
.formas .forma .panel-body {
  padding: 20px 15px 15px;
  background: #f7f8f9;
  position: relative;
  z-index: 3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.formas .forma .panel-body .forma-h4 {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
}
.formas .forma .panel-body .img-bg {
  width: 100%;
  height: 50px;
  background: no-repeat center;
  background-size: contain;
}

.formas .forma .panel-body .forma-a {
  display: block;
  font-size: 12px;
}

.formas .forma.open .panel-body {
  z-index: 2;
  box-shadow: 0px 5px 5px rgba(144, 144, 144, 0.4);
}

/* Forma Tarjetas */
.formas .forma .list-group {
  margin: 0;
}
.formas .forma .list-group .list-group-item {
  border-radius: 0;
  background: #fff;
  border: 0;
  border-top: 1px dashed #ddd;
  padding: 20px 15px;
}
.formas .forma .list-group .list-group-item:first-child {
  border-top: 0;
}

/* Forma List */
.formas .forma .list-group .list-group-item .list-inline {
  display: inline-block;
  margin: 0 0 10px;
  font-size: 12px;
  color: #969696;
}
.formas .forma .list-group .list-group-item .list-inline > li {
  padding: 0px 10px 0px 0px;
}
.formas .forma .list-group .list-group-item .list-inline > li:first-child {
  padding: 0;
}
.formas .forma .list-group .list-group-item .list-inline > li > .fa {
}

/* Forma Tarjetas */
.formas .formas .forma .list-group.tarjetas {
}
.forma .list-group.tarjetas .list-group-item .img-tarjeta {
  width: 100px;
  margin: auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 2px 2px 8px rgba(51, 51, 51, 0.3);
}

.formas .forma .list-group .list-group-item .list-group-item-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.formas .forma .list-group .list-group-item .table {
  margin: 0;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}

.formas .forma .list-group .list-group-item .forma-comentario {
  font-size: 11px;
  color: #bdbdbd;
  padding-top: 10px;
  margin-top: 10px;
}
.formas .forma .list-group .list-group-item .forma-comentario p {
  margin: 0 0 5px;
  line-height: 11px;
}

.formas .forma .list-group .list-group-item .couta {
  border-bottom: 1px #ddd dotted;
  padding: 15px 0;
}
.formas .forma .list-group .list-group-item .couta:first-child {
  padding-top: 0;
}
.formas .forma .list-group .list-group-item .couta:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.formas .forma .list-group .list-group-item .list-group-item-informacion {
  font-size: 11px;
}
.formas .forma .list-group .list-group-item .list-group-item-informacion p {
  margin: 0 0 5px;
}
.formas
  .forma
  .list-group
  .list-group-item
  .list-group-item-informacion
  p:last-child {
  margin-bottom: 0;
}

.formas .formas_grupo:last-child .forma:last-child {
  -webkit-border-radius: 1px;
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius: 1px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-radius: 1px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
</style>