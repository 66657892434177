<script>
import { Btn } from "uiv";

export default {
  components: { Btn },
  data() {
    return {
      msg:
        "No hay Resultados para los par&aacute;metro de b&uacute;squeda que ingreso."
    };
  },
  methods: {
    clear() {
      this.$emit("clear");
    }
  }
};
</script>

<template>
  <div class="sin-resultados">
    <div class="alert warning">
      <i class="mdi mdi-map-marker-remove-variant"></i>

      <p v-html="msg"></p>

      <btn type="warning" size="xs" @click="clear">
        <i class="mdi mdi-filter-remove"></i>
        ELIMINAR FILTROS
      </btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sin-resultados {
  > .alert {
    color: #8a6d3b;
    border: none;
    position: relative;
    background-color: #ffdd87;
    padding: 20px 15px 15px 90px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    > i {
      left: 10px;
      bottom: -8px;
      position: absolute;
      font-size: 75px;
    }

    > p {
      font-size: 25px;
      font-weight: 100;
      margin-bottom: 5px;
    }

    > .btn {
      font-style: normal;
      border-color: #f6bb42;
      background-color: #f6bb42;
    }
  }
}
</style>